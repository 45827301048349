import './App.css';
import {Navigate, Route, Routes} from "react-router-dom";
import pleshDark from "./theme/pleshDark";
import Vote from "./pages/Vote";
import React, {useState} from "react";
import {Button, Card, CssBaseline, Dialog, Stack, TextField, ThemeProvider, Typography} from "@mui/material";

function App() {
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [password, setPassword] = useState('');

    const checkPassword = () => {
        if (password === process.env.REACT_APP_PASS_DAM24) {
            setIsAuthorized(true);
        } else {
            alert('Password errata');
        }
    };

    if (!isAuthorized) {
        return (
            <Dialog open={true} fullScreen>
                <Card component={Stack} justifyContent={'center'} alignItems={'center'} sx={{margin: 'auto'}} p={4} spacing={2}>
                    <Typography variant={"h4"} textAlign={'center'}>Accesso Riservato</Typography>
                    <TextField
                        fullWidth
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Enter passphrase"
                    />
                    <Button fullWidth variant={'contained'} onClick={checkPassword}>Enter</Button>
                </Card>
            </Dialog>
        );
    }

    else return (
        <ThemeProvider theme={pleshDark}>
            <CssBaseline/>
            <div className="App">
                <Routes>
                    <Route path='/' element={<Navigate to={'/vote/EYds6Gyqoi7WRgxwCIpy'}/>}/>
                    <Route path='/vote/:id' element={<Vote/>}/>
                </Routes>
            </div>
        </ThemeProvider>
    );
}

export default App;
