export const API_URL = "https://api.secondstage.app"
//export const API_URL = "http://localhost:8080"
//export const TOOLPRESS_API = "https://api-tool-press-yn26wrnl4q-ey.a.run.app"
export const TOOLPRESS_API = "https://toolpress-api.2ndstage.app"

export const THEME_CONFIG = {
    mode:"dark",
    gradients: {
        default: ['#ec1d23', '#000000'],
        home_question: ['#ec1d23', '#000000'],
        applausometro: ['#ec1d23', '#000000'],
        siNo:['#3a4de4', '#00d8a1'],
        sentiment: ['#ec1d23', '#000000'],
        wordcloud: ['#ec1d23', '#000000'],
        qna: ['#ec1d23', '#000000'],
    },
    primary: '#ec1d23',
    secondary: '#263238',
    accent: '#ec1d23',
    text: '#eceff1',
    background:"rgba(38,35,35,0.9)",
    poll:{
        default:"rgba(236,29,35,0.34)",
        answered:"#ec1d23",
        correct: '#3A722D',
        wrong: '#DB0A16'
    }
}